import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import { CraftFilterExplanationItemsFragment } from '../lib/craftCategoryExplanationListsFragment.generated'
import {
  TweakwiseNavigationFacet,
  TweakwiseNavigationSelectedAttribute,
} from '../lib/customTypes'
import { LandingPageUrlFragment } from '../lib/useLandingPageUrls.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import CategoryFilterAttribute from './CategoryFilterAttribute'
import HtmlContent from './HtmlContent'
import Modal, {
  Content as ModalContent,
  TitleWrapper as ModalTitleWrapper,
} from './Modal'

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.sm} 0;
  }
`

const StyledModal = styled(Modal)`
  ${ModalTitleWrapper} {
    padding: ${theme.spacing.lg};

    @media ${minWidth('lg')} {
      padding: ${theme.spacing['2xl']} 0 ${theme.spacing.lg}
        ${theme.spacing['2xl']};
    }
  }

  ${ModalContent} {
    padding: 0 ${theme.spacing.lg} ${theme.spacing.lg};

    @media ${minWidth('lg')} {
      padding: 0 ${theme.spacing['2xl']} ${theme.spacing['2xl']};
    }
  }
`

const AttributesWrapper = styled.div`
  padding-bottom: ${theme.spacing.sm};
`

const Label = styled.span`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  display: flex;
  margin-top: ${theme.spacing.sm};
  text-decoration: underline;
`

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
  facet: TweakwiseNavigationFacet
  landingPageUrls: LandingPageUrlFragment[]
  onChange: (facet: TweakwiseNavigationSelectedAttribute) => void
  filterExplanation?: CraftFilterExplanationItemsFragment
}

const CategoryFilter = <T extends FieldValues>({
  register,
  facet,
  landingPageUrls,
  onChange,
  filterExplanation,
}: Props<T>) => {
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(false)
  const hasSelectedItems = facet.attributes.some(
    (attribute) => attribute.isSelected,
  )

  const isExpandable =
    facet.attributes.length > facet.facetSettings.nrOfShownAttributes &&
    facet.facetSettings.attributeName !== 'price' &&
    !hasSelectedItems

  const attributes = expanded
    ? facet.attributes
    : facet.attributes.slice(0, facet.facetSettings.nrOfShownAttributes)

  return (
    <>
      <StyledAccordion
        label={<Label>{facet.facetSettings.title}</Label>}
        isCollapsedDefault={!facet.facetSettings.isCollapsed}
        hasPlusIcon={false}
        onInfoClick={
          filterExplanation ? () => setShowInfoModal(true) : undefined
        }
      >
        <AttributesWrapper>
          {attributes.map((attribute) => (
            <CategoryFilterAttribute
              key={attribute.title}
              register={register}
              facetSettings={facet.facetSettings}
              attribute={attribute}
              landingPageUrls={landingPageUrls}
              onChange={onChange}
            />
          ))}

          {isExpandable && !expanded && (
            <StyledButtonUnstyled
              analyticsContext="category.filter"
              analyticsName={`expand-attributes-${facet.facetSettings.attributeName}`}
              onPress={() => setExpanded((expanded) => !expanded)}
            >
              <Trans>Show more</Trans>
            </StyledButtonUnstyled>
          )}
        </AttributesWrapper>
      </StyledAccordion>

      <StyledModal
        show={showInfoModal}
        close={() => setShowInfoModal(false)}
        title={facet.facetSettings.title}
      >
        {filterExplanation?.explanation && (
          <div>
            <HtmlContent html={filterExplanation.explanation} />
          </div>
        )}
      </StyledModal>
    </>
  )
}

export default CategoryFilter
